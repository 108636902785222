import { default as local } from "config/urls-local.json";
import { default as test } from "config/urls-test.json";
import { default as stage } from "config/urls-stage.json";
import { default as production } from "config/urls-production.json";

const { name: envName } = window.environment
  ? window.environment
  : { name: "local" };

["portfoliosAppUrl", "protectAppUrl", "searchUrl"].forEach(urlName => {
  window[urlName] = { local, test, stage, production }[envName][urlName];
});
import("./bootstrap.js");
export {};
